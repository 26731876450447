import React, { useState, useRef } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import styled from "styled-components"
import axios from "axios"
import { useStaticQuery, graphql } from "gatsby"
import ReCaptchaNotice from "./reCaptchaNotice"
import FormInput from "./formInput"
import LoadingSpinner from "./loadingSpinner"
import FormDetails from "./formDetails"

const FileLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 205px;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  background: ${(props) => props.theme.secondary};
  color: #fff;
  font-weight: 700;
  margin: 10px auto 20px;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }
  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`
const CheckboxContainer = styled.div`
  position: relative;
  font-size: 0.625rem;
  line-height: 1.4;
  display: flex;
  margin-top: 5px;
  .custom-checkbox {
    display: inline-block;
    flex-shrink: 0;
    margin: 6px 6px 0 0;
    height: 9px;
    width: 9px;
    border: 1px solid ${(props) => props.theme.secondary};
  }
  input[type="checkbox"]:checked + label .custom-checkbox {
    background: ${(props) => props.theme.secondary};
  }
  a {
    text-decoration: underline;
  }
`

const SubmitButton = styled.input`
  display: inline-block;
  position: relative;
  white-space: nowrap;
  font-family: "Montserrat";
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
  height: 40px;
  width: 100%;
  margin-top: 20px;
  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }
  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`

const StyledCheckbox = styled.input`
  cursor: pointer;
  appearance: none;
  position: absolute;
  top: 6px;
  left: 0;
  width: 10px;
  height: 10px;
`

const StyledForm = styled.form`
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  padding: 46px 70px;
  max-width: 580px;
  margin: 40px auto 80px;
  @media (max-width: 768px) {
    padding: 26px;
  }
`

const JobOfferForm = () => {
  const [fileName, setFilename] = useState()
  const [sending, setSending] = useState(false)
  const [responseMessage, setResponseMessage] = useState(null)
  const recaptchaRef = useRef(null)
  const { site, wordpressPage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          adminSiteUrl
        }
      }
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          metabox_global_settings_contact_form {
            success
            phone
            submit
            name
            resume
            error
            email
            checkboxes {
              content
              place
            }
            contactSummary
            contactDetails
          }
        }
      }
    }
  `)
  const {
    success,
    phone,
    submit,
    name,
    resume,
    error,
    email,
    checkboxes,
    careersSummary,
    careersDetails,
  } = wordpressPage.cmb2.metabox_global_settings_contact_form
  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setResponseMessage(null)
    const formData = new FormData()
    formData.append("action", "send_mail")
    formData.append("pageTitle", document.title)
    formData.append("name", document.getElementById("form-name").value)
    formData.append("email", document.getElementById("form-email").value)
    formData.append("phone", document.getElementById("form-phone").value)
    formData.append("file", document.getElementById("form-cv").files[0])
    setSending(true)
    try {
      recaptchaRef.current.execute()
      const { status } = await axios.post(
        `${site.siteMetadata.adminSiteUrl}/wp-admin/admin-post.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (status >= 200 && status < 300) {
        setResponseMessage(success)
      } else {
        throw new Error(`Response returned status code: ${status}`)
      }
    } catch {
      setResponseMessage(error)
    } finally {
      setSending(false)
    }
  }
  return (
    <StyledForm onSubmit={(e) => handleFormSubmit(e)}>
      <FormInput id="form-name" type="text" label={`${name}*`} required />
      <FormInput id="form-email" type="email" label={`${email}*`} required />
      <FormInput
        id="form-phone"
        type="tel"
        label={phone}
        pattern="\+?(\d|\s)*"
      />
      <FileLabel htmlFor="form-cv" tabIndex="0">
        {fileName ? fileName.slice(0, 20) : resume}
      </FileLabel>
      <input
        hidden
        id="form-cv"
        type="file"
        onChange={(e) => setFilename(e.target.files[0].name)}
      />

      {checkboxes.map(
        (checkbox, index) =>
          checkbox.place.includes("careers") && (
            <CheckboxContainer key={index}>
              <StyledCheckbox
                id={`form-acceptance-${index}`}
                type="checkbox"
                required
              />
              <label
                htmlFor={`form-acceptance-${index}`}
                style={{ cursor: "pointer" }}
              >
                <div className="custom-checkbox" />
                <span
                  dangerouslySetInnerHTML={{ __html: checkbox.content }}
                ></span>
              </label>
            </CheckboxContainer>
          )
      )}
      <FormDetails summary={careersSummary} details={careersDetails} />
      <ReCaptchaNotice />

      {sending ? (
        <LoadingSpinner />
      ) : (
        <SubmitButton type="submit" value={submit} />
      )}
      {responseMessage === null ? null : (
        <div style={{ marginTop: "2rem", textAlign: "center" }}>
          {responseMessage}
        </div>
      )}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="Your client site key"
      />
    </StyledForm>
  )
}
export default JobOfferForm
