import React from "react"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { BaseButton } from "./styledComponents"
import { SmallMobileFont, BigMobileFont } from "./typography"

const JobOfferBox = styled.div`
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 24px 52px 24px;
  max-width: 478px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.secondary};
  ${(props) =>
    props.orientation === "vertical" &&
    css`
      border-top: none;
      border-bottom: none;
    `}
  ${(props) =>
    props.orientation === "horizontal" &&
    css`
      border-left: none;
      border-right: none;
    `}
`

const HorizontalBorder = styled.div`
  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 23%;
    left: 0;
    border-left: 1px solid ${(props) => props.theme.secondary};
    transition: transform 1s ease-out;
    ${(props) =>
      props.side === "right" &&
      css`
        left: auto;
        right: 0;
        border-left: none;
        border-right: 1px solid ${(props) => props.theme.secondary};
      `}
  }
  ::before {
    top: 0;
    transform: scaleY(calc(1 + var(--scale)));
    transform-origin: top;
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleY(calc(1 - var(--scale)));
      `}
  }
  ::after {
    bottom: 0;
    transform: scaleY(calc(1 - var(--scale)));
    transform-origin: bottom;
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleY(calc(1 + var(--scale)));
      `}
  }
`
const VerticalBorder = styled.div`
  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 33%;
    height: 100%;
    left: 0;
    ${(props) =>
      props.side === "right" &&
      css`
        left: auto;
        right: 0;
      `}
  }
  ::before {
    top: 0;
    border-top: 1px solid ${(props) => props.theme.secondary};
    transform: scaleX(calc(1 + var(--scale)));
    transform-origin: left;
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleX(calc(1 - var(--scale)));
        transform-origin: right;
      `}
  }
  ::after {
    bottom: 0;
    border-bottom: 1px solid ${(props) => props.theme.secondary};
    transform: scaleX(calc(1 - var(--scale)));
    transform-origin: left;
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleX(calc(1 + var(--scale)));
        transform-origin: right;
      `}
  }
`

const ReadMore = styled(BaseButton)`
  text-transform: none;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
  ::after {
    right: 0;
    border-left: none;
  }
`

const JobOfferTile = ({ title, path, salary, orientation, scale }) => {
  const { wordpressPage } = useStaticQuery(graphql`
    query {
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          global_settings {
            readMore
          }
        }
      }
    }
  `)
  return (
    <JobOfferBox orientation={orientation}>
      {orientation === "horizontal" ? (
        <HorizontalBorder side="left"  />
      ) : (
        <VerticalBorder side="left"  />
      )}
      <BigMobileFont style={{ marginBottom: "0.75rem" }}>{title}</BigMobileFont>
      <SmallMobileFont style={{ marginBottom: "1.5rem" }}>
        {salary}
      </SmallMobileFont>
      <div>
        <ReadMore to={path} size="small" style={{ zIndex: "1" }}>
          {wordpressPage.cmb2.global_settings.readMore}
        </ReadMore>
      </div>
      {orientation === "horizontal" ? (
        <HorizontalBorder side="right"  />
      ) : (
        <VerticalBorder side="right"  />
      )}
    </JobOfferBox>
  )
}

export default JobOfferTile
